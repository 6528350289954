//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export abstract class RouteConfiguration
{
	public static Api: string = `/api`;
	public static HealthCheck: string = `/api/health`;
	public static Init: string = `/api/init`;
	public static Ping: string = `/api/ping`;
	public static Logout: string = `/logout`;
	public static PostLogout: string = `/post-logout`;
	public static Login: string = `/login`;
	public static ConfigScript: string = `/config.js`;
	public static RobotsTxt: string = `/student/robots.txt`;
	public static Sitemap: string = `/student/sitemap.xml`;
	public static Student: string = `/student`;
	public static TibetUserProfile: string = `https://tibet.gyldendal.no/user/edit?site=ga_riktig`;
	public static TibetUserProfileBPN: string = `https://tibet.gyldendal.no/user/edit?site=37grader`;
	public static GyldendalPage: string = `https://www.gyldendal.no`;
	public static Articles: string = `/artikler`;
	public static Lessons: string = `/steg`;
	public static Themes: string = `/temaer`;
	public static ProductNest: string = `/student`;
	public static SiteSearch: string = `/sok`;
	public static Dashboard: string = `/forside`;
	public static Bookmarks: string = `/bokmerker`;
	public static LoginPage: string = `/loggInn`;
	public static TrainingGroundPage: string = `/overom`;
	public static PreviousExamsPage: string = `/eksamener`;
	public static AuthorsForProductPage: string = `/motForfatterne`;
	public static TermsAndConditions: string = `/student/plattform/vilkar-og-betingelser`;
	public static AboutUs: string = `/student/plattform/om-oss`;
	public static BuyRedirect: string = `/student/redirecting-to-purchase`;
	public static PurchaseConfirmation: string = `/student/purchase-confirmation`;
}
