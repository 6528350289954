import * as React from "react";
import { Button } from "../../../../shared/components/button/Button";
import { Image, SanityImage } from "../../../../shared/components/image/Image";
import { NavigationLink } from "../../../../shared/components/navigationLink/NavigationLink";
import { TextCollapse } from "../../../../shared/components/textCollapse/TextCollapse";
import { useTranslations } from "../../../../shared/contexts/StaticTranslationContext";
import { ActiveCourseParams } from "../../../../shared/models/Url";
import { ImageDto } from "../../../../types/shared/dto/ImageDto";
import { useUrl } from "../../../../useRouter";
import { PaymentSidePanelViewModel } from "../../../shared/paymentDialog/PaymentSidePanelViewModel";

export interface ThemeHeroProps {
  title: string;
  description: string;
  subtitle: string;
  image?: ImageDto;
  color?: string;
  urlParams: ActiveCourseParams;
  source: "course" | "previousExams";
  hasFullCourseAccess: boolean;
  paymentSidePanelViewModel?: PaymentSidePanelViewModel;
  onLogin?: () => void;
}

export const ThemeHero: React.FC<ThemeHeroProps> = props => {
  const {
    title,
    description,
    subtitle,
    image,
    color,
    urlParams,
    source,
    paymentSidePanelViewModel,
    hasFullCourseAccess,
    onLogin,
  } = props;
  const url = useUrl();
  const translations = useTranslations();

  return (
    <section className="ThemeHero" style={{ backgroundColor: color }}>
      <div className="ThemeHero__inner">
        <div className="ThemeHero__navigation">
          {source === "course" ? (
            <NavigationLink to={url.toThemesPage(urlParams)} text={translations.goToAllThemes} type="back" />
          ) : (
            <NavigationLink to={url.toPreviousExamsPage(urlParams)} text={translations.goToPreviousExams} type="back" />
          )}
        </div>
        <div className="ThemeHero__content">
          <div className="ThemeHero__info">
            <span className="ThemeHero__subtitle">{subtitle}</span>
            <h1 className="ThemeHero__title">{title}</h1>
            <TextCollapse className="ThemeHero__description">{description}</TextCollapse>
            {!hasFullCourseAccess && urlParams.productSlug === "sykepleie" ? (
              <Button onClick={onLogin} className="ThemeHero__buyAccessButton">
                Logg inn
              </Button>
            ) : (
              <Button onClick={paymentSidePanelViewModel?.show} className="ThemeHero__buyAccessButton">
                {translations.buyAccess}
              </Button>
            )}
          </div>
          <Image.Container aspectRatio="3/2" className="ThemeHero__imageWrapper">
            {image && <SanityImage {...image} className="ThemeHero__image" />}
            {image?.credit && <Image.Credit text={image.credit} />}
          </Image.Container>
        </div>
      </div>
    </section>
  );
};
