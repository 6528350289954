import { History } from "history";
import * as React from "react";
import { createRoot } from "react-dom/client";
/* POLYFILLS */
import "intersection-observer";
import "focus-visible";
import "react-app-polyfill/stable";
/* END POLYFILLS */
import { ErrorBoundary } from "react-error-boundary";
import { ContentView, registerContentView } from "react-mvvm";
import ResizeObserver from "resize-observer-polyfill";
import { App } from "./pages/App";
import { AppView } from "./pages/AppView";
import { AboutUsView } from "./pages/aboutUs/AboutUsView";
import { AboutUsViewModel } from "./pages/aboutUs/AboutUsViewModel";
import { ArticleView } from "./pages/articles/article/ArticleView";
import { ArticleViewModel } from "./pages/articles/article/ArticleViewModel";
import { ArticleListView } from "./pages/articles/articleList/ArticleListView";
import { ArticleListViewModel } from "./pages/articles/articleList/ArticleListViewModel";
import "react-loading-skeleton/dist/skeleton.css";
import { AuthorsForProductView } from "./pages/authorsForProduct/AuthorsForProductView";
import { AuthorsForProductViewModel } from "./pages/authorsForProduct/AuthorsForProductViewModel";
import { BookmarkListView } from "./pages/bookmarks/bookmarkList/BookmarkListView";
import { BookmarkListViewModel } from "./pages/bookmarks/bookmarkList/BookmarkListViewModel";
import { BuyRedirect } from "./pages/buyRedirect/BuyRedirect";
import { BuyRedirectViewModel } from "./pages/buyRedirect/BuyRedirectViewModel";
import { CourseView } from "./pages/course/CourseView";
import { CourseViewModel } from "./pages/course/CourseViewModel";
import { DashboardView } from "./pages/dashboard/DashboardView";
import { DashboardViewModel } from "./pages/dashboard/DashboardViewModel";
import { ErrorView } from "./pages/error/ErrorView";
import { ErrorFooter } from "./pages/error/errorFooter/ErrorFooter";
import { ErrorHeader } from "./pages/error/errorHeader/ErrorHeader";
import { LessonView } from "./pages/lessons/lesson/LessonView";
import { LessonViewModel } from "./pages/lessons/lesson/LessonViewModel";
import { LoginView } from "./pages/login/LoginView";
import { LoginViewModel } from "./pages/login/LoginViewModel";
import { PreviousExamsView } from "./pages/previousExams/PreviousExamsView";
import { PreviousExamsViewModel } from "./pages/previousExams/PreviousExamsViewModel";
import { ProductView } from "./pages/product/ProductView";
import { ProductViewModel } from "./pages/product/ProductViewModel";
import { ProductNestView } from "./pages/productNest/ProductNestView";
import { ProductNestViewModel } from "./pages/productNest/ProductNestViewModel";
import { PurchaseConfirmation } from "./pages/purchaseConfirmation/PurchaseConfirmation";
import { PurchaseConfirmationViewModel } from "./pages/purchaseConfirmation/PurchaseConfirmationViewModel";
import { SiteSearchView } from "./pages/search/siteSearch/SiteSearchView";
import { SiteSearchViewModel } from "./pages/search/siteSearch/SiteSearchViewModel";
import { StepPreviewView } from "./pages/stepPreview/StepPreviewView";
import { StepPreviewViewModel } from "./pages/stepPreview/StepPreviewViewModel";
import { TermsAndConditionsView } from "./pages/termsAndConditions/TermsAndConditionsView";
import { TermsAndConditionsViewModel } from "./pages/termsAndConditions/TermsAndConditionsViewModel";
import { TestPageView } from "./pages/tests/TestPageView";
import { TestPageViewModel } from "./pages/tests/TestPageViewModel";
import { ThemeView } from "./pages/themes/theme/ThemeView";
import { ThemeViewModel } from "./pages/themes/theme/ThemeViewModel";
import { ThemeListView } from "./pages/themes/themeList/ThemeListView";
import { ThemeListViewModel } from "./pages/themes/themeList/ThemeListViewModel";
import { TrainingGroundView } from "./pages/trainingGrounds/trainingGround/TrainingGroundView";
import { TrainingGroundViewModel } from "./pages/trainingGrounds/trainingGround/TrainingGroundViewModel";
import { appRoute } from "./routes";
import { ActivityIndicator } from "./shared/components/activityIndicator/ActivityIndicator";
import { AppProvider } from "./shared/contexts/AppProvider";
import { wrapPromise } from "./shared/utils";
import { useRouter } from "./useRouter";

if (!window.ResizeObserver) {
  window.ResizeObserver = ResizeObserver;
}

registerContentView(App, p => (
  <AppProvider
    lang={p.languageService.lang}
    productParams={p.productStore.activeProduct?.productParams}
    userStore={p.userStore}
  >
    <AppView model={p} />
  </AppProvider>
));
registerContentView(CourseViewModel, p => <CourseView model={p} />);
registerContentView(ThemeViewModel, p => <ThemeView model={p} />);
registerContentView(ThemeListViewModel, p => <ThemeListView model={p} />);
registerContentView(LessonViewModel, p => <LessonView model={p} />);
registerContentView(BookmarkListViewModel, p => <BookmarkListView model={p} />);
registerContentView(ArticleListViewModel, p => <ArticleListView model={p} />);
registerContentView(ArticleViewModel, p => <ArticleView model={p} />);
registerContentView(DashboardViewModel, p => <DashboardView model={p} />);
registerContentView(SiteSearchViewModel, p => <SiteSearchView model={p} />);
registerContentView(LoginViewModel, p => <LoginView model={p} />);
registerContentView(ProductNestViewModel, p => <ProductNestView model={p} />);
registerContentView(ProductViewModel, p => <ProductView model={p} />);
registerContentView(StepPreviewViewModel, p => <StepPreviewView model={p} />);
registerContentView(TestPageViewModel, p => <TestPageView model={p} />);
registerContentView(TrainingGroundViewModel, p => <TrainingGroundView model={p} />);
registerContentView(PreviousExamsViewModel, p => <PreviousExamsView model={p} />);
registerContentView(AuthorsForProductViewModel, p => <AuthorsForProductView model={p} />);
registerContentView(TermsAndConditionsViewModel, p => <TermsAndConditionsView model={p} />);
registerContentView(AboutUsViewModel, p => <AboutUsView model={p} />);
registerContentView(BuyRedirectViewModel, p => <BuyRedirect model={p} />);
registerContentView(PurchaseConfirmationViewModel, p => <PurchaseConfirmation model={p} />);

export function isAppRoute(params: unknown): params is { page: App } {
  return (params as { page: App }).page !== undefined;
}

const useApp = (history: History) => {
  const [resource, setResource] = React.useState<
    | {
        read(): {
          page: App;
          destroy(): void;
        };
      }
    | undefined
  >();
  React.useEffect(() => {
    const _resource = wrapPromise(appRoute.bind(history));
    setResource(_resource);
  }, []);

  // Nessessary to after rebrand from riktig.gyldendal.no to qudos.no/student
  if (window.location.pathname === "/") {
    window.location.pathname = "/student";
  }

  if (window.location.pathname === "/student/") {
    window.location.pathname = "/student";
  }

  return resource?.read();
};

const LazyApp: React.FC<{ history: History }> = ({ history }) => {
  const app = useApp(history);

  return <ContentView content={app?.page} />;
};

const AppContainer = React.memo(() => {
  const stickyHeaderRef = React.useRef<HTMLDivElement>(null);
  const { history, RouterProvider } = useRouter();
  const app = useApp(history);

  return (
    <React.Suspense fallback={<ActivityIndicator isLoading />}>
      <RouterProvider>
        <ErrorBoundary
          fallbackRender={({ error }) => (
            <AppProvider lang="nb" userStore={app?.page.userStore}>
              <ErrorHeader stickyHeaderRef={stickyHeaderRef} />
              <div id="stickyHeader" ref={stickyHeaderRef} />
              <ErrorView error={error} />
              <ErrorFooter />
            </AppProvider>
          )}
        >
          <LazyApp history={history} />
        </ErrorBoundary>
      </RouterProvider>
    </React.Suspense>
  );
});

(async () => {
  const rootContainer = document.getElementById("root");
  if (!rootContainer) {
    throw new Error("Cannot find root");
  }

  const root = createRoot(rootContainer);

  root.render(
    <React.StrictMode>
      <AppContainer />
    </React.StrictMode>
  );
})();
