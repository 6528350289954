import classNames from "classnames";
import * as React from "react";
import { useMotif } from "../../contexts/MotifContext";
import { getBuildHash } from "./getBuildHash";

export const SvgIcon: React.FC<{
  iconName: string;
  alt?: string;
  className?: string;
}> = ({ iconName, alt = "", className }) => {
  const buildHash = getBuildHash();
  const formattedBuildHash = buildHash ? `.${buildHash}` : "";

  return (
    <span className={classNames("SvgIcon", className)}>
      <svg role="img" aria-label={alt}>
        <use xlinkHref={`student/iconsSprite${formattedBuildHash}.svg#sprite-${iconName}`} />
      </svg>
    </span>
  );
};

export const MotifSvgIcon: React.FC<{
  iconName: string;
  alt?: string;
  className?: string;
}> = ({ iconName, ...restProps }) => {
  const motif = useMotif();
  return <SvgIcon {...restProps} iconName={`${iconName}.${motif}`} />;
};
